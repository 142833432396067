import { EVENT_API } from "../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const getEvents = ({ groupId, memberId, page, searchedKeyword }) => {
    if (memberId) { // For group events when group member access group web link with or without login
        return axiosInstance.get(`${EVENT_API.GROUP_WEBLINK_EVENTS}?groupId=${groupId}&member_uuid=${memberId}`);
    } else if (groupId) {//For group events when group owner access group details with login
        return axiosInstance.get(`${EVENT_API.EVENTS}?groupId=${groupId}`);
    } else if (searchedKeyword) {
        return axiosInstance.get(`${EVENT_API.EVENTS}?page=${page}&search=${searchedKeyword}`); // Add pagination to dashboard all events only
    } // For events when user access dashboard all events
    return axiosInstance.get(`${EVENT_API.EVENTS}?page=${page}`); // Add pagination to dashboard all events only
};

export const createEvent = (payload) => {
    return axiosInstance.post(EVENT_API.EVENTS, payload)
};

export const getEventDetails = ({ eventId, guestId, commonEventId, userId }) => {
    if (guestId) {
        return axiosInstance.get(`${EVENT_API.EVENT_WEBLINK}?guest_uuid=${guestId}`)
    } else if (commonEventId) {
        const queryParam = userId ? `common_event_link=true&user_id=${userId}` : `common_event_link=true`
        return axiosInstance.get(`${EVENT_API.PUBLIC_EVENT.replace(':event_id', commonEventId)}?${queryParam}`)
    }
    return axiosInstance.get(`${EVENT_API.EVENTS}/${eventId}`)
};

export const updateEventDetails = (payload, eventId) => {
    return axiosInstance.post(`${EVENT_API.EVENTS}/${eventId}`, payload)
};
export const getVisiblePoll = (eventId) => {
    return axiosInstance.get(`${EVENT_API.EVENT_POLL.replace(':event_id', eventId)}/visible`);
};
export const insertOrUpdateEventPollDetails = (payload, eventId, pollId = null) => {
    // This will post post details from active user when logged in
    if (pollId === null) {
        return axiosInstance.post(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', 'null')}/insertorupdate`, payload);
    }
    return axiosInstance.post(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/insertorupdate`, payload);
};
export const answerEventPoll = (payload, eventId, pollId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/answer`, payload);
};
export const getEventPollReports = (eventId, pollId, page) => {
    return axiosInstance.get(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/showanswer?page=${page}`);
};
export const deleteEventPollDetails = (eventId, pollId) => {
    return axiosInstance.delete(EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId));
};
export const downloadEventPollReports = (eventId, pollId) => {
    return axiosInstance.get(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/exportpollanswers`);
};

export const downloadEventPollReportsPoll = (eventId) => {
    return axiosInstance.get(`${EVENT_API.EVENT_POLL_DOWNLOAD.replace(':event_id', eventId)}`);
};

export const saveEventGuestLayout = (payload, eventId) => {
    return axiosInstance.post(`${EVENT_API.EVENTS}/${eventId}/print-layout`, payload);
};

export const handleUserVisibilityEventPollDetails = (payload, eventId, pollId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/visibleanswer`, payload);
};
export const handleUserResubmitEventPoll = (payload, eventId, pollId) => {
    return axiosInstance.post(`${EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId)}/againanswer`, payload);
}
export const handleVisibilityEventPollDetails = (payload, eventId, pollId) => {
    return axiosInstance.put(EVENT_API.EVENT_POLL_FIELDS.replace(':event_id', eventId).replace(':poll', pollId), payload);
};

export const sendAPIToken = (payload, eventId) => {
    return axiosInstance.post(`${EVENT_API.GENERATED_TOKEN.replace(
        ":event_id",
        eventId
    )}`, payload)
};