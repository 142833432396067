import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { EventSettingsContext } from "../..";
import MuiTabPanel from "../../../../../../../../components/muiTabPanel";
import RFormReplies from "./RFormReplies";
import RFormView from "./RFormView";

const RFormTabs = ({ form }) => {
    const { activeSlide, setSlideType, handleNextClick } = useContext(EventSettingsContext);
    const formDetails = form?.fields;
    const useStyles = makeStyles({
        root: {
            "&.Mui-selected": {
                color: "#FF8359 !important",
                opacity: "1 !important",
                fontWeight: "bold !important"
            },
        },
    });
    const tabStyles = useStyles();

    const [value, setValue] = React.useState(0);
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    const handleEditClick = () => {
        setSlideType('editRegistrationForm')
        handleNextClick();
    }

    useEffect(() => {
        return () => {
            setValue(0); //Set 1st tab as default on unmounting
        };
    }, [activeSlide]);

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    backgroundColor: '#FFEBE4',
                    maxWidth: { sm: '100%' },
                }}
            >
                <Tab className={tabStyles.root} label='Form' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
                <Tab className={tabStyles.root} label='Replies'
                    sx={{
                        width: '50%',
                        opacity: '0.6',
                        color: '#FF8359',
                        textTransform: 'none'
                    }} />
            </Tabs>
            <MuiTabPanel value={value} index={0}>
                {/* Form has been already created and will have name  */}
                <Box sx={{ mx: { xs: 1, md: 2 } }}>
                    <RFormView formTitle={form.title} formBtnText={form?.form_button_title} formDetails={formDetails} handleEditClick={handleEditClick} />
                </Box>
            </MuiTabPanel>
            <Box
                role="tabpanel"
                hidden={value !== 1}
                id={`simple-tabpanel-${1}`}
                aria-labelledby={`simple-tab-${1}`}
            >
                {value === 1 && (
                    <RFormReplies />
                )}
            </Box>
        </>
    )
};

export default RFormTabs;
