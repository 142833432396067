import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import pluralize from 'pluralize';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { eventDetails, isPreview } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventAlbums } from "../../../../../../../../services/wEvents/eventDetails";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import EditEventViewTitleDialog from '../../EditEventViewTitleDialog';
import HorizontalScrollingCards from "../HorizontalScrollMenu";
import CreateAlbumDialog from "./components/CreateAlbumDialog";

export const EventAlbumsContext = React.createContext({});

const AlbumsSection = ({ viewId, title, addDivider }) => {
    const eventData = useSelector(eventDetails)
    const isVisible = useSelector(isPreview);
    const [dialog, setDialog] = useState({
        add: false,
        editTitle: false
    });
    const handleEdit = () => {
        setDialog({ ...dialog, editTitle: true });
    }
    const handleAdd = () => {
        document.body.classList.add("no-scroll");
        setDialog({ ...dialog, add: true });
    }
    const handleClose = (operation) => {
        document.body.classList.remove("no-scroll");
        setDialog({ ...dialog, [operation]: false });
    }
    const [dataItems, setItems] = useState([])
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    // Kept here just to update total num. of photos on uploading photos
    const handleAlbumMedia = (albumId, apiResponse, { isDeleted }) => {
        const findItem = (item) => {
            return item.id === albumId
        }
        setItems((items) => {
            let index = items.findIndex(findItem)
            isDeleted ? items[index].photos -= 1 : items[index].photos += apiResponse.length
            return [...items];
        })
    }

    useEffect(() => {
        getEventAlbums({ eventId: eventData?.id, viewId: viewId }).then((res) => {
            const eventAlbums = res.data.data
            setItems(eventAlbums)
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <EventAlbumsContext.Provider value={{ viewId, title }}>
            {(isAllPermission ? true : dataItems.length > 0) ?
                <Box>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0.5 }}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{title}</Typography>
                        {isAllPermission && isVisible ?
                            <IconButton color='warning' onClick={handleEdit}
                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                <EditIcon sx={{ width: '1.25rem' }} />
                            </IconButton>
                            : null
                        }
                        {dialog.editTitle ?
                            <EditEventViewTitleDialog
                                viewId={viewId}
                                viewTitle={title}
                                handleClose={() => handleClose('editTitle')} /> : null
                        }
                    </Stack>
                    <HorizontalScrollingCards category='Albums' items={dataItems}
                        setItems={setItems} handleAlbumMedia={handleAlbumMedia} />

                    {isAllPermission && isVisible ?
                        <Box>
                            <Button variant="text" color="warning" onClick={handleAdd} sx={{
                                textAlign: 'left', '&:hover': {
                                    background: 'none',
                                },
                            }}>
                                + Add {pluralize.singular(title)}
                            </Button>
                            {dialog.add ?
                                <CreateAlbumDialog
                                    title={title}
                                    handleClose={() => handleClose('add')}
                                    setItems={setItems} />
                                : null
                            }
                        </Box> : null
                    }
                </Box>
                : null
            }
            {(addDivider && (isAllPermission ? true : dataItems?.length > 0)) ? <Divider sx={{ my: 2 }} /> : null}
        </EventAlbumsContext.Provider>
    )
};

export default AlbumsSection;
