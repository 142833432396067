import EditIcon from '@mui/icons-material/Edit';
import { Avatar, AvatarGroup, Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EventDetailsContext } from '../../../../../..';
import FullScreenLoader from "../../../../../../../../components/Loader";
import LoginDialog from "../../../../../../../../components/LoginDialog";
import { eventDetails, isPreview } from "../../../../../../../../redux/slices/eventDataSlice";
import { userDetails } from "../../../../../../../../redux/slices/userDataSlice";
import { getEventRsvpAnswers, getEventRsvpQuestions } from "../../../../../../../../services/wEvents/eventDetails";
import { getGuestDetails } from "../../../../../../../../services/wEvents/eventGuests";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import EditEventViewTitleDialog from '../../EditEventViewTitleDialog';
import DoRsvpDialog from "./DoRsvpDialog";
import GoingListDialog from "./GoingListDialog";
import SetupRsvpDialog from "./SetupRsvpDialog";
import { useParams } from 'react-router-dom';

const RsvpListSection = ({ viewId, title, addDivider }) => {
    const { commonEventTitle } = useParams();
    const eventData = useSelector(eventDetails)
    const isVisible = useSelector(isPreview);
    const userData = useSelector(userDetails);
    const { refs } = useContext(EventDetailsContext);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    let [openDialog, setDialogOpen] = useState({
        rsvp: false,
        totalList: false,
        login: false,
        editTitle: false
    });
    const handleDialogOpen = (action) => {
        document.body.classList.add("no-scroll");
        setDialogOpen({ ...openDialog, [action]: true });
    }
    const handleDialogClose = (action) => {
        document.body.classList.remove("no-scroll");
        setDialogOpen({ ...openDialog, [action]: false });
    }

    const rsvpGiven = useRef(0);
    const [totalGuests, setTotalGuests] = useState(0);
    const [loader, setLoader] = useState(false);
    const [guestsRsvp, setGuestsRsvp] = useState([]);
    const [rsvpQuestions, setRsvpQuestions] = useState([]);
    const [oneGuestRsvp, setOneGuestRsvp] = useState([]);
    const [guestData, setGuestData] = useState(null);

    const handleRsvpBtnClick = (type) => {
        if (!userData) {
            setDialogOpen({ ...openDialog, 'login': true });
            // navigate(`${PAGE_PATHS.MOBILE_LOGIN}`);
        } else {
            handleDialogOpen('rsvp');
            if (type === 'question') {
                getEventRsvpQuestions({ eventId: eventData?.id }).then((res) => {
                    setRsvpQuestions(res.data.data[0].questions);
                }).catch((err) => {
                    console.log(err);
                })
            } else if (type === 'answer' && !rsvpGiven.current) {
                getEventRsvpQuestions({ eventId: eventData?.id }).then((res) => {
                    setOneGuestRsvp(res.data.data[0].questions);
                }).catch((err) => {
                    console.log(err);
                })
            } else if (type === 'answer') {
                getEventRsvpAnswers({ eventId: eventData?.id, currentUserAnswer: 1 }).then((res) => {
                    setOneGuestRsvp(res.data.data);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }

    const handleRsvpResponseClick = () => {
        setLoader(true);
        getEventRsvpAnswers({ eventId: eventData?.id }).then((res) => {
            console.log(res.data);
            setGuestsRsvp(res.data);
            setLoader(false);
            handleDialogOpen('totalList');
        }).catch((err) => {
            console.log(err);
            setLoader(false);
        })
    }

    useEffect(() => {
        getEventRsvpAnswers({ eventId: eventData?.id, totalCount: 1, countOnly: userData ? 0 : 1 }).then((res) => {
            rsvpGiven.current = res.data.rsvp_given;
            setTotalGuests(res.data.count);
        }).catch((err) => {
            console.log(err);
        })
    }, [openDialog.login]);

    useEffect(() => {
        if (!userData && !commonEventTitle) {
            getGuestDetails({ eventId: eventData?.id, uuid: eventData?.guest_uuid }).then((result) => {
                setGuestData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <>
            {
                (eventData?.is_rsvp_required || (!eventData?.is_rsvp_required && isAllPermission)) ?
                    <Box sx={{ position: 'relative' }}>
                        <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0.5 }}>
                            <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{title}</Typography>
                            {isAllPermission && isVisible ?
                                <IconButton color='warning' onClick={() => handleDialogOpen('editTitle')}
                                    sx={{ ":hover": { backgroundColor: 'white' } }}>
                                    <EditIcon sx={{ width: '1.25rem' }} />
                                </IconButton>
                                : null
                            }
                            {openDialog.editTitle ?
                                <EditEventViewTitleDialog
                                    viewId={viewId}
                                    viewTitle={title}
                                    handleClose={() => handleDialogClose('editTitle')} /> : null
                            }
                        </Stack>

                        <Stack direction='row' spacing={2} alignItems='center'
                            sx={{ position: 'absolute', mt: 0.5, left: '0.5%' }}>
                            <AvatarGroup total={totalGuests} max={4} sx={{ border: 'none' }}>
                                {[...Array(totalGuests).keys()].map((a, i) => <Avatar key={i} />)}
                            </AvatarGroup>
                            <Button
                                ref={refs.rsvpBtnRef}
                                variant="text" color="warning" onClick={
                                    isAllPermission ?
                                        (handleRsvpResponseClick) : null
                                }
                                sx={{ color: '#1F274A', fontWeight: 'bold' }}
                                disabled={!eventData?.is_rsvp_required}>
                                {totalGuests} Responded
                            </Button>
                            {openDialog.totalList ? <GoingListDialog guests={guestsRsvp}
                                handleClose={() => handleDialogClose('totalList')} /> : null}
                        </Stack>
                        {loader ? <FullScreenLoader /> : null}

                        {isAllPermission ?
                            <Button variant="text" color="warning" onClick={() => handleRsvpBtnClick('question')} sx={{ mt: 7 }}>
                                Setup RSVP
                            </Button>
                            :
                            <Box sx={{ mt: '4.5rem' }}>
                                <Button
                                    color="warning"
                                    variant="contained"
                                    sx={{ fontSize: '0.95rem' }}
                                    onClick={() => handleRsvpBtnClick('answer')}
                                >
                                    Do RSVP
                                </Button>
                            </Box>
                        }
                        {(openDialog.login) ? <LoginDialog open={true}
                            handleClose={() => handleDialogClose('login')} guestData={guestData} /> : null}

                        {openDialog.rsvp ?
                            isAllPermission ?
                                <SetupRsvpDialog rsvpQuestions={rsvpQuestions}
                                    setRsvpQuestions={setRsvpQuestions} handleClose={() => handleDialogClose('rsvp')} />
                                :
                                <DoRsvpDialog oneGuestRsvp={oneGuestRsvp} setOneGuestRsvp={setOneGuestRsvp}
                                    rsvpGiven={rsvpGiven}
                                    setTotalGuests={setTotalGuests}
                                    handleClose={() => handleDialogClose('rsvp')} />
                            : null
                        }
                    </Box> :
                    null
            }
            {addDivider && isAllPermission ? <Divider sx={{ my: 2 }} /> : null}
        </>
    )
};

export default RsvpListSection;
