import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { mapEventViews } from "../../../../../../utils/functions/mapEventViews";
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import { useSelector } from "react-redux";
import { eventDetails, isPreview } from "../../../../../../redux/slices/eventDataSlice";
import EditIcon from "@mui/icons-material/Edit";
import CreateEventViewDialog from "../../CreateEventViewDialog";
import CreateDragAndDropDialog from "../../CreateDragAndDropDialog";

const SubEvents = ({ views }) => {
    const eventData = useSelector(eventDetails);
    const isVisible = useSelector(isPreview);

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState({
        addView: false,
        dragView: false
    });
    const handleDialogOpen = (operation) => {
        document.body.classList.add("no-scroll");
        setDialogOpen({ ...dialogOpen, [operation]: true });
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        console.log("handle close triggered in subevents")
        setAnchorEl(null);
    };

    const handleDialogClose = (event, reason, operation, closeMenu) => {
        if (reason && reason === "backdropClick")
            return;
        if (closeMenu) {
            handleClose();
        }
        document.body.classList.remove("no-scroll");
        setDialogOpen({ ...dialogOpen, [operation]: false });
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                setAnchorEl(null);
            }
        };

        const handleScroll = () => {
            setAnchorEl(null);
        };

        if (anchorEl) {
            document.addEventListener("mousedown", handleOutsideClick);
            window.addEventListener("scroll", handleScroll);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [anchorEl]);



    return (
        <>
            {/* Info Section */}
            {window.location.pathname.includes('microsite-builder') &&
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pb: "0.25rem",
                        my: "1rem",
                        // px: "0.5rem",
                        backgroundColor: "#FFEBE4",
                        position: "relative",
                    }}
                >
                    {/* Info Text with Underline */}
                    <Box sx={{ position: "relative", p: "1rem" }}>
                        <Typography sx={{ fontSize: "1rem", fontWeight: 600, color: "#00000" }}>   {/* #FC9C71*/}
                            Info
                        </Typography>
                        {/* <Box
                        sx={{
                            position: "absolute",
                            bottom: "-17px", // Adjust underline position
                            left: '0',
                            width: "100%",
                            height: "0.2rem", // Thickness of the underline
                            backgroundColor: "#FC9C71",
                        }}
                    /> */}
                    </Box>

                    {/* Add & Reorder View Button */}
                    {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) && isVisible ? (
                        <Button
                            onClick={handleClick}
                            sx={{
                                backgroundColor: "#1C2640", // Dark Blue Background
                                color: "#FFF",
                                fontSize: "0.75rem",
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                                padding: "1rem",
                                borderRadius: "0.25rem",
                                marginBottom: "-0.20rem",
                                "&:hover": { backgroundColor: "#152033" }, // Hover Effect
                            }}
                        >
                            <EditIcon sx={{ fontSize: "1.5rem", marginRight: "0.3rem" }} />
                            {/* <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>Add & Reorder View</Typography> */}
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                MenuListProps={{
                                    sx: {
                                        py: '0.8rem'
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <CreateEventViewDialog title='Add View'
                                    dialogOpen={dialogOpen} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} />
                                <CreateDragAndDropDialog title='Manage Views' views={views}
                                    dialogOpen={dialogOpen} handleDialogOpen={handleDialogOpen} handleDialogClose={handleDialogClose} />
                            </Menu>
                        </Button>
                    ) : null}
                </Box>
            }

            {/* Note Box */}
            {isVisible && ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) &&
                (< Box
                    sx={{
                        backgroundColor: "#FFEBE4", // Light grayish background
                        padding: "10px 15px",
                        borderRadius: "0.25rem",
                        fontSize: "12px",
                        color: "#00000",
                        fontWeight: 500,
                        mb: 2,
                        maxWidth: '90%',
                        mx: '5%'
                    }}
                >
                    <Typography sx={{ fontSize: "1rem", fontWeight: 600, px: '2rem', py: '0.6rem' }}>
                        Note:{" "}
                        <span style={{ fontWeight: 400 }}>
                            You may fill in all the details as per your requirement and
                            whatever details you fill, will be displayed on the event page.
                            Whichever field is empty, won’t be displayed on the event page!
                        </span>
                    </Typography>
                </Box >)}

            <Box sx={{ pb: '1rem' }}>
                {views.map((v, i) => (
                    <React.Fragment key={i}>
                        {/* last param defines adding divider in every event view except last one */}
                        {mapEventViews(v?.id, v?.title, v?.type, i !== views.length - 1)}
                    </React.Fragment>
                ))}
            </Box>
        </>
    );
};

export default SubEvents;
