import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/userDataSlice'
import { eventDetailReducer, isPreviewSliceReducer } from '../slices/eventDataSlice'
import groupReducer from '../slices/groupDetailSlice'
import { sidebarReducer, sidebarShortcutsReducer } from '../slices/sidebarDataSlice'

export default configureStore({
    reducer: {
        User: userReducer,
        Event: eventDetailReducer,
        Group: groupReducer,
        Sidebar: sidebarReducer,
        SidebarShortcuts: sidebarShortcutsReducer,
        IsPreview: isPreviewSliceReducer,
    }
})  