import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Checkbox, DialogActions, DialogContent, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventSettingsContext } from "../..";
import { eventDetails, update_event_form_state } from "../../../../../../../../redux/slices/eventDataSlice";
import { createEventForm } from "../../../../../../../../services/wEvents/eventDetails";
import { manageEventFormPayload } from "../../../../../../../../utils/functions/managePayload";
import AddFormQuestionsDialog from "./AddFormQuestionsDialog";
import RFormView from "./RFormView";

const CreateRegistrationForm = () => {
    const dispatch = useDispatch();
    const eventData = useSelector(eventDetails);
    const { activeSlide, setSlide, setRegistrationForm } = useContext(EventSettingsContext);
    const [add, setAdd] = useState(false);
    const [view, setView] = useState(true);
    const initialValues = {
        0: { question: "Name", type: "text", mandatory: true },
        1: { question: "Country Code", type: "number", mandatory: true },
        2: { question: "Mobile Number", type: "text", mandatory: true },
        3: { question: "Email", type: "text", mandatory: true },
    }

    const initialFieldDetails = [
        { name: 0, label: 'Name', type: 'text', mandatory: true },
        { name: 1, label: 'Country Code', type: 'number', mandatory: true },
        { name: 2, label: 'Mobile Number', type: 'text', mandatory: true },
        { name: 3, label: 'Email', type: 'text', mandatory: true },
    ]
    const [fieldDetails, setFieldDetails] = useState(initialFieldDetails)
    const initialForm = {
        fields: Object.values(initialValues)
    }

    const { handleSubmit, register, setValue, reset, watch, unregister } = useForm({
        defaultValues: { ...initialValues, formTitle: "Guest Registration Form" }
    });

    const handleInitialFormEditClick = () => {
        setView(false);
    };

    const handleAddFormQuestion = (data, chipData) => {
        const lastElementId = (fieldDetails?.slice(-1)[0]?.name ?? 0);
        // Create a new object to store the question details
        const questionDetails = {
            name: lastElementId + 1,
            label: data.question,
            type: data.questionType,
            mandatory: false,
            options: chipData.length > 0 ? chipData : undefined
        };

        if (data.questionType === 'file') {
            // Handle file data if the question type is 'file'
            questionDetails.file = data.file; // Assuming `data.file` is an array of files
        }

        // Update the field details
        setValue(`${lastElementId + 1}`, questionDetails);
        setFieldDetails((details) => [...details, questionDetails]);

        // Close the dialog and show success message
        setAdd(false);
        toast.success("Question added", {
            position: "top-right",
            theme: "dark"
        });
    };

    const handleDeleteItem = (index, itemName) => {
        fieldDetails.splice(index, 1);
        setFieldDetails([...fieldDetails]);
        unregister(`${itemName}`);
    }

    const mySubmit = (data) => {
        const payload = manageEventFormPayload(data);
        createEventForm(payload, eventData?.id).then((res) => {
            setRegistrationForm(res.data.data)
            toast.success("Registration form created successfully", {
                position: "top-right",
                theme: "dark"
            });
            dispatch(update_event_form_state(1));
            setSlide(1);
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        return () => {
            reset()
            setFieldDetails(initialFieldDetails);
        };
    }, [activeSlide]);

    const renderItem = (item) => {
        const fieldName = item.name;
        switch (item.type) {
            case 'textarea':
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                            <Checkbox color='warning'
                                checked={item.mandatory}
                                onChange={(e) => {
                                    let index = fieldDetails.indexOf(item)
                                    fieldDetails[index].mandatory = e.target.checked
                                    setFieldDetails([...fieldDetails])
                                    setValue(`${fieldName}.mandatory`, e.target.checked)
                                }}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                size="small"
                                color="warning"
                                defaultValue={item.label}
                                {...register(`${fieldName}.question`)}
                            />
                            <IconButton size='small' onClick={() => handleDeleteItem(fieldDetails.indexOf(item), fieldName)} >
                                <DeleteIcon color="warning" />
                            </IconButton>
                        </Stack>
                    </>
                );
            default:
                return (
                    <>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                            {/* We'll not allow to perform question on 1st two questions while creating form for event */}
                            {!([0, 1, 2, 3].includes(fieldName)) &&
                                <Checkbox color='warning'
                                    checked={item.mandatory}
                                    onChange={(e) => {
                                        let index = fieldDetails.indexOf(item)
                                        fieldDetails[index].mandatory = e.target.checked
                                        setFieldDetails([...fieldDetails])
                                        setValue(`${fieldName}.mandatory`, e.target.checked)
                                    }}
                                />
                            }
                            <TextField
                                fullWidth
                                size="small"
                                color="warning"
                                defaultValue={item.label}
                                inputProps={{ readOnly: ([0, 1, 2, 3].includes(fieldName)) ? true : false }}
                                {...register(`${fieldName}.question`)}
                            />
                            {!([0, 1, 2, 3].includes(fieldName)) &&
                                <IconButton size='small' onClick={() => handleDeleteItem(fieldDetails.indexOf(item), fieldName)} >
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            }
                        </Stack>
                    </>
                );
        }
    }

    return (
        <>
            {
                view ? <Stack spacing={1} sx={{ mb: { xs: '4rem', md: '4rem' }, p: 3 }}>
                    <RFormView formDetails={initialForm.fields} handleEditClick={handleInitialFormEditClick} />
                </Stack> :
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(mySubmit)}
                    >
                        <DialogContent dividers={true} sx={{
                            p: { xs: '1rem', md: '1.5rem 1.5rem' },
                            height: { xs: '26rem', md: '31rem' }, overflow: 'auto'
                        }}>
                            <Stack spacing={3}>
                                <TextField
                                    required
                                    fullWidth
                                    color="warning"
                                    label="Form Name"
                                    defaultValue="Guest Registration Form"
                                    {...register("formTitle", { required: true })}
                                />
                                <TextField
                                    fullWidth
                                    crequired
                                    color="warning"
                                    label="Button Text"
                                    defaultValue="Registration"
                                    {...register("btnText", { required: true })}
                                />
                                <Box>
                                    <Typography sx={{ color: '#6F738B', fontSize: '0.8rem' }}>
                                        Please mark the fields as mandatory by clicking on the checkboxes
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                                        Form Questions
                                    </Typography>
                                </Box>
                                {fieldDetails.map((item, i) => (
                                    // Given this key to unmount checkbox state on unmounting component
                                    <React.Fragment key={Math.random() + '_' + i}>
                                        {renderItem(item)}
                                    </React.Fragment>
                                ))}
                                <Divider sx={{ my: 1 }} />
                                {add ? <Box sx={{
                                    width: '90%',
                                    borderRadius: '5px',
                                    margin: '1rem auto !important',
                                    boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)'
                                }}>
                                    <AddFormQuestionsDialog setAdd={setAdd} handleAddFormQuestion={handleAddFormQuestion} />
                                </Box> :
                                    <Button variant="text" color="warning" sx={{ width: 'auto' }} onClick={() => setAdd(true)}>+ Add Question</Button>
                                }
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ mr: 1, my: 0.5 }}>
                            <Button type="submit" color='warning' variant="contained"
                                sx={{ boxShadow: 'none' }}
                                disabled={watch('formTitle') ? false : true}>
                                Create
                            </Button>
                        </DialogActions>
                    </form>
            }
        </>
    )
};

export default CreateRegistrationForm;
