import { createSlice } from "@reduxjs/toolkit";

export const eventDetailSlice = createSlice({
    name: 'Event',
    initialState: {
        eventDetails: {}
    },
    reducers: {
        update_event_details: (state, action) => {
            // console.log("state", state.eventDetails)
            if (Object.keys(action.payload).length === 0) {
                state.eventDetails.is_paid = action.payload;
            }
            else {
                state.eventDetails = action.payload
            }
        },
        update_event_self_checkin_field: (state, action) => {
            state.eventDetails.is_self_check_in = action.payload.isSelfCheckIn ? 1 : 0;
        },
        update_event_is_poll: (state, action) => {
            state.eventDetails.is_poll = action.payload.hasPolls ? 1 : 0;
        },
        update_event_api_token_field: (state, action) => {
            state.eventDetails.event_token = action.payload.eventToken;
        },
        update_event_form_state: (state, action) => {
            state.eventDetails.has_registration_form = action.payload
        },
        update_event_poll_state: (state, action) => {
            state.eventDetails.is_poll = action.payload
        },
        update_event_media: (state, action) => {
            state.eventDetails.media = action.payload
            state.eventDetails.event_main_photo = action.payload[0].path
        },
        clear_event_media: (state, action) => {
            state.eventDetails.media = []
            state.eventDetails.event_main_photo = null
        },
        clear_event_details: (state, action) => {
            state.eventDetails = {}
        }
    }
})

export const isPreviewSlice = createSlice({
    name: 'IsPreview',
    initialState: {
        isPreview: true
    },
    reducers: {
        set_preview: (state, action) => {
            state.isPreview = action.payload.isPreview;
        },
        toggle_preview: (state, action) => {
            state.isPreview = !state.isPreview
        }
    }
})

export const { update_event_details, update_event_self_checkin_field, update_event_is_poll, update_event_api_token_field,
    update_event_ticket_count, update_event_poll_state, update_event_form_state, update_event_media,
    clear_event_media, clear_event_details } = eventDetailSlice.actions
export const { toggle_preview, set_preview } = isPreviewSlice.actions

export const isPreview = (state) => state.IsPreview.isPreview
export const eventDetails = (state) => state.Event.eventDetails

export const isPreviewSliceReducer = isPreviewSlice.reducer
export const eventDetailReducer = eventDetailSlice.reducer