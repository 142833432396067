import React, { useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { GroupMembersContext } from "../../../../GroupMembersContext";

const ReviewMemberInviteMsgDialog = ({ handleClose }) => {
    const { inviteMsg } = useContext(GroupMembersContext);

    return (
        <>
            <Dialog
                open={true}
                onClose={() => handleClose('reviewMsg')}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '50%' }, m: { xs: 0 },
                        maxWidth: { xs: '90%', sm: '70%' },
                    }
                }}
            >
                <DialogTitle>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.8rem' } }}>Review Invitation</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
                        {inviteMsg?.notification ?
                            <Box>
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    Notification
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    color="warning"
                                    label="Message"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    sx={{ mt: 2 }}
                                    defaultValue={inviteMsg?.notificationMessage !== undefined ? inviteMsg.notificationMessage :
                                        'There are many variations of passages of Lorem Ipsum available, but the majority have different.'}
                                />
                            </Box>
                            : null
                        }
                        {inviteMsg?.sms ?
                            <Box>
                                <Typography sx={{ display: 'inline-block', fontSize: { xs: '1rem' } }}>
                                    SMS
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    color="warning"
                                    label="Message"
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    sx={{ mt: 2 }}
                                    defaultValue={inviteMsg?.smsMessage} />
                            </Box> : null
                        }
                        {inviteMsg?.whatsapp ?
                            <Box>
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    Whats app
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    color="warning"
                                    label="Message"
                                    sx={{ mt: 2 }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    defaultValue={inviteMsg?.whatsappMessage}
                                />
                            </Box> : null
                        }
                        {inviteMsg?.email ?
                            <Box>
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    Email
                                </Typography>
                                <Editor
                                    apiKey="3avavfkbn8e4pohhh8f37shar5pyxrdn7hph1igffd9v9hk6"
                                    initialValue={inviteMsg?.emailMessage}
                                    disabled={true}
                                    init={{
                                        height: '65vh',
                                        readonly: 1,
                                        menubar: false,
                                        toolbar: false,
                                    }}
                                />
                            </Box> : null
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined' onClick={() => handleClose('reviewMsg')} sx={{ mr: 1, mt: 1 }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default ReviewMemberInviteMsgDialog;
