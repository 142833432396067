import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { eventDetails, isPreview, update_event_details } from "../../../../../../../../redux/slices/eventDataSlice";
import { updateEventDetails } from "../../../../../../../../services/wEvents/event";
import { ALL_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import EditEventViewTitleDialog from "../../EditEventViewTitleDialog";
import AboutEventDialog from "./AboutEventDialog";
import DOMPurify from 'dompurify';

const AboutEventSection = ({ viewId, title, addDivider }) => {
    const editorRef = useRef(null);
    const isVisible = useSelector(isPreview);
    const dispatch = useDispatch();
    const eventData = useSelector(eventDetails)
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    const [dialog, setDialog] = useState({
        details: false,
        editTitle: false
    });
    const handleOpen = (operation) => {
        document.body.classList.add("no-scroll");
        setDialog({ ...dialog, [operation]: true });
    }
    const handleClose = (operation) => {
        document.body.classList.remove("no-scroll");
        setDialog({ ...dialog, [operation]: false });
    }

    const convertToPlain = (html) => {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    const handleEdit = () => {
        setEdit(true)
    }
    const handleCancel = () => {
        setEdit(false)
    }
    const saveDescription = () => {
        setLoading(true);
        if (editorRef.current) {
            let payload = {
                '_method': 'PUT',
                'description': editorRef.current.getContent()
            }
            updateEventDetails(payload, eventData?.id).then((res) => {
                dispatch(update_event_details(res.data.data))
                setEdit(false);
                setLoading(false);
                toast.success("Event description updated successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                if (err.response.status === 413) {
                    toast.error("Content is beyond limit of space!!", {
                        position: "top-right",
                        theme: "dark"
                    });
                } else {
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                }
            })
        }
    }

    const sanitizedData = (data) => ({
        __html: DOMPurify.sanitize(data)
    })

    return (
        <>
            {(isAllPermission ? true : eventData?.description) ?
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0.5 }}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{title}</Typography>
                        {isAllPermission && isVisible ?
                            <IconButton color='warning' onClick={() => handleOpen('editTitle')}
                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                <EditIcon sx={{ width: '1.25rem' }} />
                            </IconButton>
                            : null
                        }
                        {dialog.editTitle ?
                            <EditEventViewTitleDialog
                                viewId={viewId}
                                viewTitle={title}
                                handleClose={() => handleClose('editTitle')} /> : null
                        }
                    </Stack>
                    {edit ? <Stack direction='row' spacing={1}>
                        <Button color='warning' variant='outlined' onClick={handleCancel}>Cancel</Button>
                        <LoadingButton
                            color="warning"
                            variant="outlined"
                            onClick={saveDescription}
                            loading={loading ? true : false}
                            loadingPosition={loading ? "start" : null}
                            startIcon={loading ? <SaveIcon /> : null}
                        >
                            {loading ? 'Saving..' : 'Save'}
                        </LoadingButton>
                    </Stack> :
                        null
                    }
                </Stack>
                : null
            }

            <Box>
                {edit ? <>
                    <Box sx={{ my: 3 }}>
                        <Editor
                            apiKey="3avavfkbn8e4pohhh8f37shar5pyxrdn7hph1igffd9v9hk6"
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={eventData?.description}
                            init={{
                                selector: 'textarea',
                                height: '400px',
                                plugins: ['lists', 'autolink', 'link', 'table', 'emoticons', 'image',
                                    'fullscreen', 'preview', 'insertdatetime', 'media', 'quickbars'],
                                menubar: false,
                                toolbar: 'bold italic underline forecolor | undo redo | fontfamily fontsize | ' +
                                    'alignleft aligncenter alignright | ' +
                                    'bullist numlist outdent indent | ' +
                                    'fullscreen preview | image media insertdatetime table | link emoticons removeformat',
                                quickbars_insert_toolbar: false,
                                toolbar_mode: 'sliding',
                                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                                quickbars_selection_toolbar: 'bold italic underline forecolor backcolor | formatselect | quicklink',
                                file_picker_types: 'image media', // Only allow image files to be selected
                                file_picker_callback: (callback) => {
                                    // Create a file input element
                                    const input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', '*');

                                    // When the user selects a file, read it and create a blob URI
                                    input.onchange = () => {
                                        const file = input.files[0];
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = () => {
                                            const blobUri = reader.result;
                                            callback(blobUri, { title: file.name });
                                        };
                                    };

                                    // Trigger the file input element
                                    input.click();
                                },
                                media_live_embeds: true, // Enable live video embeds
                                media_poster: false, // Disable showing video posters
                                media_alt_source: false, // Disable showing alternative video sources
                                media_dimensions: false, // Disable specifying video dimensions
                            }}
                        />
                    </Box>
                </> :
                    (
                        eventData?.description ?
                            <Box sx={{ border: '1px solid lightgrey', borderRadius: '10px', paddingLeft: '15px' }}>
                                <Typography className="event-desc" dangerouslySetInnerHTML={sanitizedData(eventData?.description)} />
                            </Box> :
                            <>
                                {isAllPermission ?
                                    <IconButton color='warning' onClick={handleEdit} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                        <Typography sx={{ fontSize: '0.875rem' }}>+ Add Event Description</Typography>
                                    </IconButton>
                                    : null
                                }
                            </>
                    )
                }
                {eventData?.description ?
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ pb: 0 }}>
                        {isAllPermission ?
                            <Button color='warning' onClick={handleEdit}>
                                Edit
                            </Button>
                            : null
                        }
                        <Button color='warning'
                            onClick={() => handleOpen('details')}>
                            See More
                        </Button>
                    </Stack>
                    : null
                }
            </Box>

            {(addDivider && (isAllPermission ? true : eventData?.description)) ? <Divider sx={{ my: 1.5 }} /> : null}

            {
                dialog.details ?
                    <AboutEventDialog handleClose={() => handleClose('details')}
                        desc={eventData?.description} />
                    : null
            }
        </>
    )
};

export default AboutEventSection;
