import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { toast } from "react-toastify";
import { EventSettingsContext } from "../..";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import {
    bulkDeleteEventFormFields,
    getEventForm,
    insertOrUpdateEventFormFields
} from "../../../../../../../../services/wEvents/eventDetails";
import { manageEventFormPayload } from "../../../../../../../../utils/functions/managePayload";
import AddFormQuestionsDialog from "./AddFormQuestionsDialog";

const EditRegistrationForm = () => {
    const eventData = useSelector(eventDetails);
    const [loader, setLoader] = useState(false);
    const { setSlide, registrationForm, setRegistrationForm } = useContext(EventSettingsContext);
    const [add, setAdd] = useState(false);
    const [showAddQuestion, setShowAddQuestion] = useState(true);

    let initialValues = {};
    let initialDetails = registrationForm?.fields;
    initialDetails?.forEach((r, index) => {
        initialValues[index] = { ...r, exists: true };
        r.name = index;
        r.exists = true;
        r.mandatory = Boolean(r.mandatory);
    });
    const initialEditItems = initialDetails.map((item) => ({
        id: item.id,
        edit: false
    }));
    const [editItems, setEditItems] = useState(initialEditItems);

    const [fieldDetails, setFieldDetails] = useState(initialDetails);
    const [idsToDelete, setIdsToDelete] = useState([]);
    const [chipData, setChipData] = React.useState([]);
    const [editOptionIndex, setEditOptionIndex] = useState(null); // Add this state
    const [editOptionValue, setEditOptionValue] = useState(""); // For storing the current option value being edited

    const handleAddFormQuestion = (data, chipData) => {
        const lastElementId =
            fieldDetails?.slice(-1)[0]?.id ??
            fieldDetails?.slice(-1)[0]?.name ??
            0;
        setValue(`${lastElementId + 1}`, {
            type: data.questionType,
            options: chipData.length > 0 ? chipData : undefined,
            mandatory: false,
            exists: false
        });
        setFieldDetails((details) => [
            ...details,
            {
                name: lastElementId + 1,
                question: data.question,
                type: data.questionType,
                mandatory: false,
                exists: false
            }
        ]);
        setAdd(false);
        const updatedEditItems = [
            ...editItems,
            { id: lastElementId + 1, edit: false }
        ];
        setEditItems(updatedEditItems);
        toast.success("Question added", {
            position: "top-right",
            theme: "dark"
        });
    };

    const handleEditItem = (itemToEdit) => {
        const updatedEditItems = editItems.map((item) => ({
            ...item,
            edit: item.id === (itemToEdit.id ?? itemToEdit.name) ? true : false
        }));
        setEditItems(updatedEditItems);
        setEditOptionIndex(null); // Reset the edit option state
        if (itemToEdit?.type === "radio" || itemToEdit?.type === "checkbox") {
            const itemDetails = fieldDetails.find(
                (it) => it.id === itemToEdit.id || it.name === itemToEdit.name
            );
            setShowAddQuestion(false);
            setChipData(itemDetails?.options || []);
        }
    };

    const handleCancelEditItem = () => {
        const updatedEditItems = editItems.map((item) => ({
            ...item,
            edit: false
        }));
        setEditItems(updatedEditItems);
        resetField("options");
        setChipData([]);
        setShowAddQuestion(true);
    };

    const handleDeleteItem = (field_name) => {
        const fieldToDelete = fieldDetails.find((d) => d.name === field_name);
        setFieldDetails((details) =>
            details.filter((d) => d !== fieldToDelete)
        );
        if (fieldToDelete.exists) {
            setIdsToDelete((prev) => [...prev, fieldToDelete.id]);
        }
        unregister(`${field_name}`);
    };

    const handleAddOption = () => {
        const option = watch("options");
        option && setChipData((chipData) => [...chipData, option]);
        resetField("options");
    };

    const handleDeleteOption = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleEditFormQuestion = (item) => {
        const updatedFieldDetails = fieldDetails.map((field) => {
            if (field.name === item.name || field.id === item.id) {
                return {
                    ...field,
                    question: watch(`${item.name}.question`),
                    options: chipData // Updating the options from chipData
                };
            }
            return field;
        });
        setFieldDetails(updatedFieldDetails);
        setValue(`${item.name}.options`, chipData); // Update form value with the options
        handleCancelEditItem();
        toast.success("Question Edited", {
            position: "top-right",
            theme: "dark"
        });
    };

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        reset,
        resetField,
        unregister,
        watch
    } = useForm({
        defaultValues: initialValues
    });

    const mySubmit = (data) => {
        setLoader(true);
        const deletePayload = {};

        // Ensure all fields have the correct structure before passing to manageEventFormPayload
        const formattedData = {
            formTitle: data.formTitle,
            btnText: data.btnText,
            ...Object.entries(data).reduce((acc, [key, value]) => {
                if (key !== "formTitle" && key !== 'btnText' && value) {
                    acc[key] = {
                        ...value,
                        mandatory: value.mandatory || false,
                        options: value.options || []
                    };
                }
                return acc;
            }, {})
        };

        const insertUpdatePayload = manageEventFormPayload(formattedData);

        const deleteFormFields = () => {
            deletePayload["form_field_ids"] = idsToDelete;
            return bulkDeleteEventFormFields(eventData?.id, deletePayload);
        };

        const insertOrUpdateFormFields = () => {
            return insertOrUpdateEventFormFields(
                eventData?.id,
                registrationForm?.id,
                insertUpdatePayload
            );
        };

        Promise.allSettled([deleteFormFields(), insertOrUpdateFormFields()])
            .then((results) => {
                return getEventForm({
                    eventId: eventData?.id,
                    formId: registrationForm?.id
                });
            })
            .then((res) => {
                setRegistrationForm(res.data.data);
                setLoader(false);
                toast.success("Registration form updated", {
                    position: "top-right",
                    theme: "dark"
                });
                setSlide(1);
                setIdsToDelete([]);
            })
            .catch((err) => {
                console.error(err);
                setLoader(false);
                toast.error(
                    "Something went wrong in updating registration form",
                    {
                        position: "top-right",
                        theme: "dark"
                    }
                );
            });
    };

    const handleCancel = () => {
        setFieldDetails(initialDetails);
        setIdsToDelete([]);
        setSlide(1);
        reset();
    };

    const renderItem = (item) => {
        const fieldName = item.name;
        switch (item.type) {
            case "textarea":
            case "text":
            case "date":
            case "email":
            case "number":
            case "radio":
            case "checkbox":
            case "switch":
            case "file":
                return (
                    <>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            {![0, 1, 2, 3].includes(fieldName) &&
                                editItems.find(
                                    (editItem) =>
                                        editItem.id === (item.id ?? item.name)
                                )?.edit && (
                                    <Checkbox
                                        color="warning"
                                        checked={item.mandatory}
                                        onChange={(e) => {
                                            let index =
                                                fieldDetails.indexOf(item);
                                            fieldDetails[index].mandatory =
                                                e.target.checked;
                                            setFieldDetails([...fieldDetails]);
                                            setValue(
                                                `${fieldName}.mandatory`,
                                                e.target.checked
                                            );
                                        }}
                                    />
                                )}

                            <TextField
                                fullWidth
                                size="small"
                                color="warning"
                                defaultValue={item.question}
                                inputProps={{
                                    readOnly:
                                        [0, 1, 2, 3].includes(fieldName) ||
                                            !editItems.find(
                                                (editItem) =>
                                                    editItem.id ===
                                                    (item.id ?? item.name)
                                            )?.edit
                                            ? true
                                            : false
                                }}
                                {...register(`${fieldName}.question`)}
                            />
                            {![0, 1, 2, 3].includes(fieldName) && (
                                <>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleEditItem(item)}
                                    >
                                        <EditIcon color="warning" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handleDeleteItem(item.name)
                                        }
                                    >
                                        <DeleteIcon color="warning" />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                        {(item.type === "radio" || item.type === "checkbox") &&
                            editItems.find(
                                (editItem) =>
                                    editItem.id === (item.id ?? item.name)
                            )?.edit && (
                                <Box
                                    sx={{
                                        margin: "1rem auto !important",
                                        width: "90%",
                                        borderRadius: "5px",
                                        boxShadow:
                                            "0 0 3px 3px rgba(0, 0, 50, 0.15)"
                                    }}
                                >
                                    <Box>
                                        <DialogContent>
                                            <Stack spacing={3}>
                                                <Stack>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={{
                                                            xs: 1,
                                                            sm: 2
                                                        }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            color="warning"
                                                            label="Add option"
                                                            inputProps={{
                                                                onKeyDown: (
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        "Enter"
                                                                    ) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        document
                                                                            .getElementById(
                                                                                "addOption"
                                                                            )
                                                                            .click();
                                                                    }
                                                                }
                                                            }}
                                                            {...register(
                                                                "options"
                                                            )}
                                                        />
                                                        <Button
                                                            id="addOption"
                                                            color="warning"
                                                            sx={{
                                                                fontSize:
                                                                    "2rem",
                                                                p: 0,
                                                                minWidth: "1rem"
                                                            }}
                                                            onClick={
                                                                handleAddOption
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </Stack>

                                                    {chipData?.length > 0 && (
                                                        <Stack
                                                            direction="column"
                                                            spacing={1}
                                                            sx={{ mt: 1, p: 0 }}
                                                        >
                                                            {chipData.map(
                                                                (option, i) => (
                                                                    <Stack
                                                                        key={i}
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        {editOptionIndex ===
                                                                            i ? (
                                                                            <>
                                                                                <TextField
                                                                                    inputRef={(
                                                                                        el
                                                                                    ) =>
                                                                                        el &&
                                                                                        el.focus()
                                                                                    } // Automatically focus the input when editing
                                                                                    value={
                                                                                        editOptionValue
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setEditOptionValue(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    color="warning"
                                                                                />
                                                                                <Button
                                                                                    color="warning"
                                                                                    onClick={() => {
                                                                                        const updatedOptions =
                                                                                            [
                                                                                                ...chipData
                                                                                            ];
                                                                                        updatedOptions[
                                                                                            i
                                                                                        ] =
                                                                                            editOptionValue; // Update the option in chipData
                                                                                        setChipData(
                                                                                            updatedOptions
                                                                                        );
                                                                                        setEditOptionIndex(
                                                                                            null
                                                                                        ); // Stop editing mode
                                                                                    }}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                                <Button
                                                                                    color="warning"
                                                                                    onClick={() =>
                                                                                        setEditOptionIndex(
                                                                                            null
                                                                                        )
                                                                                    } // Cancel editing
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Chip
                                                                                    color="warning"
                                                                                    label={
                                                                                        option
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setEditOptionIndex(
                                                                                            i
                                                                                        ); // Set the option to be edited
                                                                                        setEditOptionValue(
                                                                                            option
                                                                                        ); // Load the current value into input
                                                                                    }}
                                                                                />
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() =>
                                                                                        handleDeleteOption(
                                                                                            option
                                                                                        )()
                                                                                    }
                                                                                >
                                                                                    <DeleteIcon color="warning" />
                                                                                </IconButton>
                                                                            </>
                                                                        )}
                                                                    </Stack>
                                                                )
                                                            )}
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </DialogContent>
                                        <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                            <Button
                                                onClick={handleCancelEditItem}
                                                color="warning"
                                                variant="outlined"
                                                sx={{ boxShadow: "none" }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="warning"
                                                variant="contained"
                                                onClick={() =>
                                                    handleEditFormQuestion(item)
                                                }
                                                sx={{ boxShadow: "none" }}
                                                disabled={chipData.length < 2}
                                            >
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                </Box>
                            )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <form autoComplete="off" onSubmit={handleSubmit(mySubmit)}>
                <DialogContent
                    dividers={true}
                    sx={{
                        p: { xs: "1rem", md: "1.5rem 1.5rem" },
                        height: { xs: "26rem", md: "31rem" },
                        overflow: "auto"
                    }}
                >
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            color="warning"
                            label="Form title"
                            defaultValue={registrationForm?.title}
                            error={errors?.formTitle ? true : false}
                            helperText={
                                errors?.formTitle
                                    ? "This is required field"
                                    : null
                            }
                            {...register("formTitle", { required: true })}
                        />
                        <TextField
                            fullWidth
                            color="warning"
                            label="Button Text"
                            defaultValue={registrationForm?.form_button_title ?? 'Registration'}
                            error={errors?.formTitle ? true : false}
                            helperText={
                                errors?.formTitle
                                    ? "This is required field"
                                    : null
                            }
                            {...register("btnText", { required: true })}
                        />
                        <Box>
                            <Typography
                                sx={{ color: "#6F738B", fontSize: "0.8rem" }}
                            >
                                Mark as mandatory fields by clicking on the
                                check boxes
                            </Typography>
                            <Typography sx={{ fontWeight: "bold", mt: 2 }}>
                                Form Details
                            </Typography>
                        </Box>

                        {fieldDetails.map((item, i) => (
                            <React.Fragment>
                                {renderItem(item, i)}
                            </React.Fragment>
                        ))}
                        {showAddQuestion && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                {add ? (
                                    <Box
                                        sx={{
                                            margin: "1rem auto !important",
                                            width: "90%",
                                            borderRadius: "5px",
                                            boxShadow:
                                                "0 0 3px 3px rgba(0, 0, 50, 0.15)"
                                        }}
                                    >
                                        <AddFormQuestionsDialog
                                            setAdd={setAdd}
                                            handleAddFormQuestion={
                                                handleAddFormQuestion
                                            }
                                        />
                                    </Box>
                                ) : (
                                    <Button
                                        variant="text"
                                        color="warning"
                                        sx={{ width: "auto" }}
                                        onClick={() => setAdd(true)}
                                    >
                                        + Add Question
                                    </Button>
                                )}
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button
                        onClick={handleCancel}
                        color="warning"
                        variant="outlined"
                        sx={{ boxShadow: "none" }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? 'Saving...' : 'Save'}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    );
};

export default EditRegistrationForm;
