import React, { useState, useEffect, useRef, useContext } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Button,
    TextField,
    InputAdornment,
    Grid,
    Typography,
    Slider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Alert,
    Modal,
    IconButton
} from "@mui/material";
import moment from "moment";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import QrGenerator from "../../../../../EventDetailCards/components/EventQrcode/QrGenerator";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import Draggable from "react-draggable";
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { toast } from "react-toastify";
import { saveEventGuestLayout } from "../../../../../../../../services/wEvents/event";
import { EventGuestsContext } from "../../EventGuestContext";

const GuestDetailsTable = ({
    guestDetails,
    additionalDetails,
    eventData,
    isPrint,
    isDownload,
}) => {
    const { elementsPositions: elementLocations, setElementsPositions: setElementLocations, pageDimension } = useContext(EventGuestsContext);
    const [selectedRows, setSelectedRows] = useState(["guestUuid"]); // Include 'guestUuid' by default
    const [pageWidth, setPageWidth] = useState(pageDimension?.width || 0); // Default width in cm
    const [pageHeight, setPageHeight] = useState(pageDimension?.height || 0); // Default height in cm
    const [showLayoutEditor, setShowLayoutEditor] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [qrCodeSize, setQrCodeSize] = useState(elementLocations?.qr_size || 100); // Default QR code size (in px)
    const [qrCodeAlignment, setQrCodeAlignment] = useState("center"); // center, left, right
    const [qrCodeDisplay, setQrCodeDisplay] = useState("block"); // block (new line) or inline
    const [collisionDetected, setCollisionDetected] = useState(false);
    const [previewScale, setPreviewScale] = useState(1); // Scale for preview zoom

    const previewRef = useRef(null);
    const containerRef = useRef(null);
    const widthDebounceTimerRef = useRef(null);
    const heightDebounceTimerRef = useRef(null);

    // Calculate px to cm conversion factor (assuming 96 DPI)
    const pxToCmFactor = 37.8; // 1cm ≈ 37.8px at 96 DPI
    // Handle page dimension changes and adjust elements to stay in bounds
    // Replace the first useEffect in your file

    const constrainPositionToPage = (position, pageWidthPx, pageHeightPx, elementSize = 100) => {
        if (!position) return { x: 0, y: 0 };

        // Get safe boundaries accounting for element size
        const maxX = Math.max(0, pageWidthPx - elementSize);
        const maxY = Math.max(0, pageHeightPx - elementSize);

        // Use modulo to wrap positions that are out of bounds
        let x = position.x;
        let y = position.y;

        if (x < 0 || x > maxX) {
            x = Math.abs(x % (maxX || 1));
        }

        if (y < 0 || y > maxY) {
            y = Math.abs(y % (maxY || 1));
        }

        return { x, y };
    };

    // With these lines that constrain positions on initialization
    const [qrCodePosition, setQrCodePosition] = useState(() => {
        // If no position data, default to origin
        if (!elementLocations?.guestUuid) return { x: 0, y: 0 };

        // Calculate page dimensions in pixels
        const pageWidthPx = (pageDimension?.width || 21) * pxToCmFactor;
        const pageHeightPx = (pageDimension?.height || 29.7) * pxToCmFactor;

        // Constrain QR code position with its size
        return constrainPositionToPage(
            elementLocations.guestUuid,
            pageWidthPx,
            pageHeightPx,
            qrCodeSize
        );
    });

    const [elementsPositions, setElementsPositions] = useState(() => {
        // If no layout data, return empty object
        if (!elementLocations) return {};

        // Calculate page dimensions in pixels
        const pageWidthPx = (pageDimension?.width || 21) * pxToCmFactor;
        const pageHeightPx = (pageDimension?.height || 29.7) * pxToCmFactor;

        const constrainedPositions = {};

        // Process all position data except QR code
        Object.entries(elementLocations).forEach(([key, position]) => {
            if (key !== "guestUuid") {
                constrainedPositions[key] = constrainPositionToPage(position, pageWidthPx, pageHeightPx);
            }
        });

        return constrainedPositions;
    });

    useEffect(() => {
        // Get current container dimensions in px
        const containerWidth = pageWidth * pxToCmFactor;
        const containerHeight = pageHeight * pxToCmFactor;

        // Always adjust element positions to ensure they stay within bounds
        const adjustedPositions = {};
        let hasAdjustments = false;

        // Check and adjust regular elements
        Object.entries(elementsPositions).forEach(([key, position]) => {
            // Get element dimensions - use estimates if element doesn't exist yet
            const elementWidth = 150; // Default estimate
            const elementHeight = 30; // Default estimate

            // Calculate safe boundaries
            const maxX = Math.max(0, containerWidth - elementWidth - 20);
            const maxY = Math.max(0, containerHeight - elementHeight - 20);

            let newX = position.x;
            let newY = position.y;

            // Check if element exceeds right boundary
            if (newX < 0 || newX > maxX) {
                newX = Math.abs(newX % (maxX || 1));
                hasAdjustments = true;
            }

            // Check if element exceeds bottom boundary
            if (newY < 0 || newY > maxY) {
                newY = Math.abs(newY % (maxY || 1));
                hasAdjustments = true;
            }

            if (newX !== position.x || newY !== position.y) {
                adjustedPositions[key] = { x: newX, y: newY };
            }
        });

        // Check and adjust QR code position
        if (qrCodePosition) {
            const maxQrX = Math.max(0, containerWidth - qrCodeSize - 20);
            const maxQrY = Math.max(0, containerHeight - qrCodeSize - 20);

            let newQrX = qrCodePosition.x;
            let newQrY = qrCodePosition.y;

            if (newQrX < 0 || newQrX > maxQrX) {
                newQrX = Math.abs(newQrX % (maxQrX || 1));
                hasAdjustments = true;
            }

            if (newQrY < 0 || newQrY > maxQrY) {
                newQrY = Math.abs(newQrY % (maxQrY || 1));
                hasAdjustments = true;
            }

            if (newQrX !== qrCodePosition.x || newQrY !== qrCodePosition.y) {
                setQrCodePosition({ x: newQrX, y: newQrY });
            }
        }

        // Apply adjustments to regular elements if needed
        if (hasAdjustments && Object.keys(adjustedPositions).length > 0) {
            setElementsPositions(prev => ({
                ...prev,
                ...adjustedPositions
            }));
        }
    }, [pageWidth, pageHeight, qrCodeSize]);

    useEffect(() => {
        setPageWidth(pageDimension?.width);
        setPageHeight(pageDimension?.height);
        if (guestDetails?.uuid) {
            setSelectedRows((prevSelectedRows) => [
                ...new Set([...prevSelectedRows, "guestUuid"])
            ]);
        }
    }, [guestDetails?.uuid]);

    const saveLayout = () => {
        if (eventData?.id && guestDetails?.id) {
            saveEventGuestLayout({ print_layout: { ...elementsPositions, guestUuid: qrCodePosition, qr_size: qrCodeSize }, }, eventData.id,)
                .then((res) => {
                    setElementLocations({ ...elementsPositions, guestUuid: qrCodePosition, qr_size: qrCodeSize })
                    toast.success(res.data.message ?? "Layout saved successfully!", {
                        position: "top-right",
                        theme: "dark"
                    });
                })
                .catch((err) => {
                    console.error("Error saving layout:", err);
                    toast.error("Failed to save layout.", {
                        position: "top-right",
                        theme: "dark"
                    });
                });
        }
    };

    const debounceTimerRef = useRef(null);
    const handleQRSizeChange = (e) => {
        const newSize = parseInt(e.target.value, 10);
        const oldSize = qrCodeSize;

        // Clear the previous debounce timer to avoid multiple setTimeout calls
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            if (isNaN(newSize) || newSize < 30) {
                toast.error("QR code size must be at least 30px", {
                    position: "top-right",
                    theme: "dark"
                });
                setQrCodeSize(oldSize); // revert to old size
                return;
            }

            const { pageHeightPx, pageWidthPx } = {
                pageHeightPx: parseInt(pageHeight * pxToCmFactor, 10),
                pageWidthPx: parseInt(pageWidth * pxToCmFactor, 10)
            }

            if (newSize > pageHeightPx) {
                toast.error(`QR code size cannot exceed ${pageHeightPx}px`, {
                    position: "top-right",
                    theme: "dark"
                });
                setQrCodeSize(pageHeightPx); // revert to old size
                return;
            }
            if (newSize > pageWidthPx) {
                toast.error(`QR code size cannot exceed ${pageWidthPx}px`, {
                    position: "top-right",
                    theme: "dark"
                });
                setQrCodeSize(pageWidthPx); // revert to old size
                return;
            }

            // Update qrCodeSize with valid new size
        }, 2000); // debounce delay of 2000ms (2 seconds)
        setQrCodeSize(newSize);
    };

    // Initialize positions for selected elements
    useEffect(() => {
        const initialPositions = {};
        selectedRows.forEach((row, index) => {
            if (!elementsPositions[row]) {
                initialPositions[row] = { x: 0, y: index * 30 }; // Stack elements vertically with some spacing
            }
        });

        if (Object.keys(initialPositions).length > 0) {
            setElementsPositions(prev => ({ ...prev, ...initialPositions }));
        }
    }, [selectedRows]);

    const handleCheckboxChange = (event, key) => {
        if (event.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, key]);
        } else {
            setSelectedRows(selectedRows.filter((item) => item !== key));
        }
    };

    const handlePageWidthChange = (e) => {
        const newWidth = parseFloat(e.target.value);
        const oldWidth = pageWidth;

        // Update immediately for responsive UI
        setPageWidth(newWidth);

        // Clear the previous debounce timer
        if (widthDebounceTimerRef.current) {
            clearTimeout(widthDebounceTimerRef.current);
        }

        // Set a new debounce timer for validation
        widthDebounceTimerRef.current = setTimeout(() => {
            if (isNaN(newWidth) || newWidth <= 0) {
                toast.error("Width must be greater than 0", {
                    position: "top-right",
                    theme: "dark"
                });
                setPageWidth(.1); // revert to old width
                return;
            }

            // Add any additional validation logic here if needed

            // If we're here, the new width is valid
            console.log(`Page width validated: ${newWidth}cm`);
        }, 2000); // 2 second delay
    };

    const handlePageHeightChange = (e) => {
        const newHeight = parseFloat(e.target.value);
        const oldHeight = pageHeight;

        // Update immediately for responsive UI
        setPageHeight(newHeight);

        // Clear the previous debounce timer
        if (heightDebounceTimerRef.current) {
            clearTimeout(heightDebounceTimerRef.current);
        }

        // Set a new debounce timer for validation
        heightDebounceTimerRef.current = setTimeout(() => {
            if (isNaN(newHeight) || newHeight <= 0) {
                toast.error("Height must be greater than 0", {
                    position: "top-right",
                    theme: "dark"
                });
                setPageHeight(.1); // revert to old height
                return;
            }

            // Add any additional validation logic here if needed

            // If we're here, the new height is valid
            console.log(`Page height validated: ${newHeight}cm`);
        }, 2000); // 2 second delay
    };

    const handleDragStop = (key, e, data) => {
        const containerWidth = pageWidth * pxToCmFactor;
        const containerHeight = pageHeight * pxToCmFactor;

        // Get element dimensions
        const element = document.querySelector(`[data-key="${key}"]`);
        if (!element) return;

        const rect = element.getBoundingClientRect();
        const elementWidth = rect.width;
        const elementHeight = rect.height;

        // Constrain position within container bounds using modulo for wrapping if needed
        let newX = Math.max(0, Math.min(data.x, containerWidth - elementWidth - 10));
        let newY = Math.max(0, Math.min(data.y, containerHeight - elementHeight - 10));

        // If position is still somehow outside bounds, use modulo to bring it in bounds
        if (newX < 0 || newX >= containerWidth - elementWidth - 10) {
            newX = Math.abs(newX % (containerWidth - elementWidth - 10));
        }

        if (newY < 0 || newY >= containerHeight - elementHeight - 10) {
            newY = Math.abs(newY % (containerHeight - elementHeight - 10));
        }

        const updatedPositions = {
            ...elementsPositions,
            [key]: { x: newX, y: newY }
        };

        setElementsPositions(updatedPositions);
        detectCollisions(updatedPositions);
    };

    const detectCollisions = (positions) => {
        if (!previewRef.current) return;

        const elements = previewRef.current.querySelectorAll('.draggable-element');
        let collisionFound = false;

        const elementsArray = Array.from(elements);
        for (let i = 0; i < elementsArray.length; i++) {
            const rect1 = elementsArray[i].getBoundingClientRect();

            for (let j = i + 1; j < elementsArray.length; j++) {
                const rect2 = elementsArray[j].getBoundingClientRect();

                if (!(rect1.right < rect2.left ||
                    rect1.left > rect2.right ||
                    rect1.bottom < rect2.top ||
                    rect1.top > rect2.bottom)) {
                    collisionFound = true;
                    break;
                }
            }

            if (collisionFound) break;
        }

        setCollisionDetected(collisionFound);
    };

    // Get value for a specific field
    const getFieldValue = (key) => {
        if (key === "guestUuid") {
            return null; // QR code is handled separately
        } else if (key === "event_user_photo_path") {
            return guestDetails?.event_user_photo_path;
        } else if (key.includes("additional_form_replies") && additionalDetails?.additional_form_replies) {
            const index = parseInt(key.split("-")[1], 10);
            const item = additionalDetails.additional_form_replies[index];
            return item.type === "file"
                ? item.answer  // Image URL
                : item.type === "checkbox"
                    ? JSON.parse(item.answer).join(", ")
                    : item.answer;
        } else {
            switch (key) {
                case "name":
                    return `${guestDetails?.name} ${guestDetails?.invited_by_user_name ? `(Substitute of ${guestDetails?.invited_by_user_name})` : ""}`;
                case "email":
                    return guestDetails?.email;
                case "contact":
                    return `+${guestDetails?.dialing_code} ${guestDetails?.mobile}`;
                case "registration_time":
                    return moment(additionalDetails?.registration_time).format("Do MMMM YYYY, h:mm A");
                case "amount_paid":
                    return `${parseDecimalNumber(additionalDetails?.ticket_data?.amount_paid, 2)} ${amountCurrencySymbol || ""}`;
                case "payment_time":
                    return moment(new Date(additionalDetails?.ticket_data?.payment_time * 1000)).format("D MMM YYYY, h:mm A");
                case "tickets_bought_time":
                    return moment(additionalDetails?.ticket_data?.tickets_bought_time).format("Do MMMM YYYY, h:mm A");
                case "registered_by":
                    return additionalDetails?.registered_by;
                case "ticket_title":
                    return additionalDetails?.ticket_data?.ticket_title;
                case "tickets_bought":
                    return additionalDetails?.ticket_data?.tickets_bought;
                case "ticket_id":
                    return additionalDetails?.ticket_data?.ticket_id;
                case "payment_method":
                    return additionalDetails?.ticket_data?.payment_method;
                case "payment_id":
                    return additionalDetails?.ticket_data?.payment_id;
                default:
                    return guestDetails[key] || additionalDetails[key];
            }
        }
    };

    // Get field label name
    const getFieldLabel = (key) => {
        if (key === "guestUuid") return "QR Code";
        if (key === "event_user_photo_path") return "Photo";
        if (key.includes("additional_form_replies") && additionalDetails?.additional_form_replies) {
            const index = parseInt(key.split("-")[1], 10);
            return additionalDetails.additional_form_replies[index]?.question || "Custom Field";
        }

        const keyMapping = {
            name: "Name",
            email: "Email",
            contact: "Contact",
            registration_time: "Registration Time",
            amount_paid: "Amount Paid",
            payment_time: "Payment Time",
            tickets_bought_time: "Tickets Bought Time",
            registered_by: "Registered By",
            ticket_title: "Ticket Name",
            tickets_bought: "Tickets",
            ticket_id: "Ticket ID",
            payment_method: "Payment Method",
            payment_id: "Payment ID"
        };

        return keyMapping[key] || key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    };

    // Preview component with draggable and resizable elements
    const PreviewContent = () => {
        return (
            <div
                ref={previewRef}
                style={{
                    width: `${pageWidth}cm`,
                    height: `${pageHeight}cm`,
                    border: '1px solid #ccc',
                    position: 'relative',
                    overflow: 'hidden',
                    margin: '0 auto',
                    backgroundColor: 'white',
                    padding: '0.5cm',
                    transform: `scale(${previewScale})`,
                    transformOrigin: 'top left',
                    transition: 'transform 0.2s ease'
                }}
            >
                {selectedRows.map((key, index) => {
                    if (key === "guestUuid") return null; // QR code is handled separately

                    const value = getFieldValue(key);
                    const position = elementsPositions[key] || { x: 0, y: index * 30 };
                    const label = getFieldLabel(key);

                    // Skip if no value
                    if (!value) return null;

                    // Handle photo separately
                    if (key === "event_user_photo_path") {
                        return (
                            <Draggable
                                key={key}
                                bounds="parent"
                                position={position}
                                onStop={(e, data) => handleDragStop(key, e, data)}
                            >
                                <Box
                                    className="draggable-element"
                                    data-key={key}
                                    style={{
                                        cursor: 'move',
                                        position: 'absolute',
                                        zIndex: 1000,
                                        border: '1px dashed rgba(255, 130, 89, 0.5)',
                                        padding: '2px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        width: 'fit-content'
                                    }}
                                >
                                    <Typography style={{ fontSize: '.75rem', fontWeight: 'bold', backgroundColor: 'rgba(255, 131, 89, 0.7)', color: 'white', padding: '.125rem' }}>
                                        {label}
                                    </Typography>
                                    <Box
                                        component='img'
                                        src={value}
                                        alt="Profile Photo"
                                        style={{
                                            borderRadius: "50%",
                                            width: "2.5cm",
                                            height: "2.5cm",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    />
                                </Box>
                            </Draggable>
                        );
                    }

                    // Handle additional form replies with file type
                    if (key.includes("additional_form_replies")) {
                        const index = parseInt(key.split("-")[1], 10);
                        const item = additionalDetails?.additional_form_replies[index];

                        if (item && item.type === "file" && item.answer) {
                            return (
                                <Draggable
                                    key={key}
                                    bounds="parent"
                                    position={position}
                                    onStop={(e, data) => handleDragStop(key, e, data)}
                                >
                                    <Box
                                        className="draggable-element"
                                        data-key={key}
                                        style={{
                                            cursor: 'move',
                                            position: 'absolute',
                                            zIndex: 1000,
                                            border: '2px dashed #FF8359',
                                            padding: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                            width: 'fit-content'
                                        }}
                                    >
                                        <Box style={{ fontSize: '10px', fontWeight: 'bold', backgroundColor: 'rgba(255, 131, 89, 0.7)', color: 'white', padding: '2px' }}>
                                            {label}
                                        </Box>
                                        <Box
                                            component='img'
                                            src={item.answer}
                                            alt={item.question}
                                            style={{
                                                width: "100%", maxWidth: "100px", display: 'flex',
                                                alignItems: 'center', justifyContent: 'center',
                                            }}
                                        />
                                    </Box>
                                </Draggable>
                            );
                        }
                    }

                    // Handle regular text elements
                    return (
                        <Draggable
                            key={key}
                            bounds="parent"
                            position={position}
                            onStop={(e, data) => handleDragStop(key, e, data)}
                        >
                            <Box
                                className="draggable-element"
                                data-key={key}
                                style={{
                                    cursor: 'move',
                                    position: 'absolute',
                                    zIndex: 1000,
                                    border: '2px dashed #FF8359',
                                    padding: '4px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    width: 'fit-content'
                                }}
                            >
                                <Box style={{ fontSize: '10px', fontWeight: 'bold', backgroundColor: 'rgba(255, 131, 89, 0.7)', color: 'white', padding: '2px' }}>
                                    {label}
                                </Box>
                                <Box style={{ fontWeight: 'bold', padding: '4px' }}>
                                    {value}
                                </Box>
                            </Box>
                        </Draggable>
                    );
                })}

                {selectedRows.includes("guestUuid") && guestDetails?.uuid && (
                    <Draggable
                        bounds="parent"
                        position={qrCodePosition}
                        onStop={(e, data) => {
                            // Ensure QR code stays within bounds
                            const maxX = previewRef.current.clientWidth - qrCodeSize - 10;
                            const maxY = previewRef.current.clientHeight - qrCodeSize - 10;
                            const newX = Math.max(0, Math.min(data.x, maxX));
                            const newY = Math.max(0, Math.min(data.y, maxY));
                            setQrCodePosition({ x: newX, y: newY });
                        }}
                    >
                        <Box
                            className="draggable-element"
                            data-key="guestUuid"
                            style={{
                                cursor: 'move',
                                position: 'absolute',
                                zIndex: 1000,
                                border: '2px dashed #FF8359',
                                padding: '4px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                width: 'fit-content'
                            }}
                        >
                            <Box style={{
                                textAlign: 'center',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                backgroundColor: 'rgba(255, 131, 89, 0.7)',
                                color: 'white',
                                padding: '2px'
                            }}>
                                QR Code
                            </Box>
                            <Box style={{
                                width: qrCodeSize, height: qrCodeSize, display: 'flex',
                                alignItems: 'center', justifyContent: 'center'
                            }}>
                                <QRCode value={guestDetails.uuid} size={qrCodeSize - 10} />
                            </Box>
                        </Box>
                    </Draggable>
                )}
            </div>
        );
    };

    // The actual printable content that respects all our settings
    const PrintableContent = React.forwardRef((props, ref) => {
        const {
            selectedRows,
            guestDetails,
            additionalDetails,
            amountCurrencySymbol,
            parseDecimalNumber,
            pageWidth,
            pageHeight,
            qrCodeSize,
            qrCodePosition,
            qrCodeAlignment,
            qrCodeDisplay,
            elementsPositions
        } = props;

        return (
            <div
                ref={ref}
                className="print-content-container"
                style={{
                    width: `${pageWidth}cm`,
                    height: `${pageHeight}cm`,
                    maxWidth: `${pageWidth}cm`,
                    margin: 0,
                    padding: "0.5cm",
                    overflow: "hidden",
                    position: "relative",
                    boxSizing: "border-box",
                    backgroundColor: "white",
                    pageBreakInside: "avoid",
                    pageBreakBefore: "auto",
                    pageBreakAfter: "auto"
                }}
            >
                {/* Ensure no additional content can appear */}
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                @page {
                    size: A4;
                    margin: 0;
                }
                
                @media print {
                    /* Force zero margins on the page to remove headers/footers */
                    @page {
                        margin: 0mm !important;
                        padding: 0mm !important;
                    }
                    
                    /* Reset all elements that might affect layout */
                    * {
                        -webkit-print-color-adjust: exact !important;
                        color-adjust: exact !important;
                    }
                    
                    /* Fix for Microsoft Print to PDF specifically */
                    html, body {
                        margin: 0 !important;
                        padding: 0 !important;
                        border: 0 !important;
                        overflow: hidden !important;
                        width: 100% !important;
                        height: 100% !important;
                    }
                    
                    /* Hide all browser-generated content */
                    body::before, body::after {
                        display: none !important;
                        content: none !important;
                    }
                    
                    /* Hide URL display in links */
                    a[href]:after {
                        content: none !important;
                    }
                    
                    /* Hide all kinds of headers and footers */
                    head, header, footer, .header-content, .footer-content {
                        display: none !important;
                        visibility: hidden !important;
                        height: 0 !important;
                    }
                    
                    /* Force document title to be empty to prevent header display */
                    title {
                        display: none !important;
                    }
                }
            `
                    }}
                />
                {selectedRows.map((key, index) => {
                    if (key === "guestUuid") return null; // QR code is handled separately

                    const value = getFieldValue(key);
                    const position = elementsPositions[key] || { x: 0, y: index * 30 };

                    // Skip if no value
                    if (!value) return null;

                    // Handle photo separately
                    if (key === "event_user_photo_path") {
                        return (
                            <div
                                key={key}
                                style={{
                                    position: 'absolute',
                                    left: `${position.x}px`,
                                    top: `${position.y}px`,
                                }}
                            >
                                <img
                                    src={value}
                                    alt="Profile Photo"
                                    style={{
                                        borderRadius: "50%",
                                        width: "2.5cm",
                                        height: "2.5cm"
                                    }}
                                />
                            </div>
                        );
                    }

                    // Handle additional form replies with file type
                    if (key.includes("additional_form_replies")) {
                        const index = parseInt(key.split("-")[1], 10);
                        const item = additionalDetails?.additional_form_replies[index];

                        if (item && item.type === "file" && item.answer) {
                            return (
                                <div
                                    key={key}
                                    style={{
                                        position: 'absolute',
                                        left: `${position.x}px`,
                                        top: `${position.y}px`,
                                    }}
                                >
                                    <img
                                        src={item.answer}
                                        alt={item.question}
                                        style={{ width: "100%", maxWidth: "100px" }}
                                    />
                                </div>
                            );
                        }
                    }

                    // Handle regular text elements
                    return (
                        <div
                            key={key}
                            style={{
                                position: 'absolute',
                                left: `${position.x}px`,
                                top: `${position.y}px`,
                                fontWeight: 'bold'
                            }}
                        >
                            {value}
                        </div>
                    );
                })}

                {selectedRows.includes("guestUuid") && guestDetails?.uuid && (
                    <div
                        style={{
                            position: 'absolute',
                            left: `${qrCodePosition.x}px`,
                            top: `${qrCodePosition.y}px`,
                            textAlign: qrCodeAlignment,
                            display: qrCodeDisplay,
                            width: `${qrCodeSize}px`,
                            height: `${qrCodeSize}px`,
                        }}
                    >
                        <QRCode value={guestDetails.uuid} size={qrCodeSize - 10} />
                    </div>
                )}
            </div>
        );
    });

    useEffect(() => {
        // Create a meta tag to instruct the browser not to add headers/footers
        const metaTag = document.createElement('meta');
        metaTag.name = 'viewport';
        metaTag.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
        document.head.appendChild(metaTag);

        // Create global print style
        const globalPrintStyle = document.createElement('style');
        globalPrintStyle.id = 'global-print-style';
        globalPrintStyle.innerHTML = `
          @page {
              size: A4;
              margin: 0 !important;
          }
          
          @media print {
              body::before, body::after {
                  display: none !important;
                  content: none !important;
              }
              
              /* Force hiding headers and footers */
              @page { margin: 0 !important; }
              
              html, body {
                  margin: 0 !important;
                  padding: 0 !important;
                  overflow: hidden !important;
              }
              
              /* Hide browser-generated content */
              head, header, footer {
                  display: none !important;
                  visibility: hidden !important;
                  height: 0 !important;
              }
              
              /* Hide URL display in links */
              a[href]:after {
                  content: none !important;
              }
          }
        `;
        document.head.appendChild(globalPrintStyle);

        return () => {
            if (metaTag.parentNode) metaTag.parentNode.removeChild(metaTag);
            const styleElem = document.getElementById('global-print-style');
            if (styleElem) styleElem.remove();
        };
    }, []);

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: ${pageWidth}cm ${pageHeight}cm;
            margin: 0;
        }
        
        @media print {
            body::before, body::after {
                display: none !important;
                content: none !important;
            }
            
            /* Force hiding headers and footers */
            @page {
                margin: 0 !important;
            }
            
            html, body {
                margin: 0 !important;
                padding: 0 !important;
                overflow: hidden !important;
                background-color: white;
                width: ${pageWidth}cm !important;
                height: ${pageHeight}cm !important;
            }
            
            /* Hide all browser-generated content */
            head, header, footer, .header-content, .footer-content {
                display: none !important;
                visibility: hidden !important;
                height: 0 !important;
            }
            
            /* Remove URL display */
            a[href]:after {
                content: none !important;
            }
        }
    `,
        removeAfterPrint: true,
        documentTitle: " ", // Empty document title to avoid header display
        onBeforeGetContent: () => {
            // Store original title and set an empty one
            const originalTitle = document.title;
            document.title = " ";

            // Add a special style tag to force no headers/footers
            const style = document.createElement('style');
            style.id = 'print-header-removal';
            style.textContent = `
            @page { margin: 0 !important; }
            @media print {
                body::before, body::after { display: none !important; content: none !important; }
                a[href]:after { content: none !important; }
            }
        `;
            document.head.appendChild(style);

            // Create an iframe to print from to avoid browser headers/footers
            const iframe = document.createElement('iframe');
            iframe.style.position = 'fixed';
            iframe.style.right = '0';
            iframe.style.bottom = '0';
            iframe.style.width = '0';
            iframe.style.height = '0';
            iframe.style.border = 'none';
            document.body.appendChild(iframe);

            return Promise.resolve(() => {
                document.title = originalTitle;
                const styleElem = document.getElementById('print-header-removal');
                if (styleElem) styleElem.remove();
                if (iframe && iframe.parentNode) {
                    iframe.parentNode.removeChild(iframe);
                }
            });
        },
        onBeforePrint: () => {
            document.title = " ";
        },
        copyStyles: true,
    });

    const paidRegistrationColumns = {
        "Ticket Name": "ticket_title",
        "Ticket ID": "ticket_id",
        [guestDetails?.generated_by_owner ? "Tickets" : "Tickets Purchased"]:
            "tickets_bought",
        "Amount Paid": "amount_paid",
        "Payment ID": "payment_id",
        "Payment Method": "payment_method"
    };

    let freeRegistrationColumns = {
        "Ticket Name": "ticket_title",
        "Ticket ID": "ticket_id",
        [guestDetails?.generated_by_owner ? "Tickets" : "Tickets Bought"]:
            "tickets_bought"
    };

    if (!eventData?.is_paid && additionalDetails?.ticket_data?.payment_id) {
        freeRegistrationColumns = {
            ...freeRegistrationColumns,
            "Amount Paid": "amount_paid",
            "Payment ID": "payment_id",
            "Payment Method": "payment_method"
        };
    }

    let amountCurrencySymbol = null;
    if (additionalDetails?.ticket_data?.amount_currency) {
        amountCurrencySymbol = ticketCurrencies.find(
            (t) => t.value === additionalDetails?.ticket_data?.amount_currency
        )?.symbol;
    }

    // Open the full-screen layout editor modal
    const openLayoutEditor = () => {
        setOpenModal(true);
        setShowLayoutEditor(true);
    };

    return (
        <Box sx={{ position: "relative" }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Detail</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={selectedRows.includes("name")}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, "name")
                                    }
                                />
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell sx={{ padding: "0.6rem" }}>
                                {guestDetails?.name}{" "}
                                {guestDetails?.invited_by_user_name
                                    ? `(Substitute of ${guestDetails?.invited_by_user_name})`
                                    : null}
                            </TableCell>
                        </TableRow>
                        {guestDetails?.email && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes("email")}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "email")
                                        }
                                    />
                                </TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {guestDetails?.email}
                                </TableCell>
                            </TableRow>
                        )}
                        {guestDetails?.dialing_code && guestDetails?.mobile && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "contact"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "contact")
                                        }
                                    />
                                </TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    +{guestDetails.dialing_code}{" "}
                                    {guestDetails.mobile}
                                </TableCell>
                            </TableRow>
                        )}
                        {guestDetails?.event_user_photo_path && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "event_user_photo_path"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "event_user_photo_path"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Profile Photo</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    <Box
                                        component="img"
                                        src={guestDetails?.event_user_photo_path}
                                        alt="uploaded file"
                                        sx={{ width: "100%", maxWidth: 200 }}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.registration_time && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "registration_time"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "registration_time"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Registration Time</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {moment(
                                        additionalDetails?.registration_time
                                    ).format("Do MMMM YYYY, h:mm A")}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.ticket_data &&
                            Object.entries(
                                eventData?.is_paid
                                    ? paidRegistrationColumns
                                    : freeRegistrationColumns
                            ).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedRows.includes(
                                                value
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(e, value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{key}</TableCell>
                                    <TableCell sx={{ padding: "0.6rem" }}>
                                        {key === "Amount Paid" ? (
                                            <>
                                                {parseDecimalNumber(
                                                    additionalDetails?.ticket_data[value],
                                                    2
                                                )}{" "}
                                                <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                            </>
                                        ) : (
                                            additionalDetails?.ticket_data[value]
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        {additionalDetails?.ticket_data?.payment_time && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "payment_time"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "payment_time"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Payment Time</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {moment(
                                        new Date(
                                            additionalDetails?.ticket_data
                                                ?.payment_time * 1000
                                        )
                                    ).format("D MMM YYYY, h:mm A")}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.ticket_data
                            ?.tickets_bought_time && (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedRows.includes(
                                                "tickets_bought_time"
                                            )}
                                            onChange={(e) =>
                                                handleCheckboxChange(
                                                    e,
                                                    "tickets_bought_time"
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>Tickets Bought Time</TableCell>
                                    <TableCell sx={{ padding: "0.6rem" }}>
                                        {moment(
                                            additionalDetails?.ticket_data
                                                ?.tickets_bought_time
                                        ).format("Do MMMM YYYY, h:mm A")}
                                    </TableCell>
                                </TableRow>
                            )}
                        {additionalDetails?.registered_by && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes(
                                            "registered_by"
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                e,
                                                "registered_by"
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>Registered By</TableCell>
                                <TableCell sx={{ padding: "0.6rem" }}>
                                    {additionalDetails?.registered_by}
                                </TableCell>
                            </TableRow>
                        )}
                        {additionalDetails?.additional_form_replies?.map((reply, index) => (
                            reply.answer && (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedRows.includes(`additional_form_replies-${index}`)}
                                            onChange={(e) => handleCheckboxChange(e, `additional_form_replies-${index}`)}
                                        />
                                    </TableCell>
                                    <TableCell>{reply.question}</TableCell>
                                    <TableCell sx={{ padding: "0.6rem" }}>
                                        {reply.type === "checkbox" ? (
                                            JSON.parse(reply.answer).join(", ")
                                        ) : reply.type === "file" && reply.answer ? (
                                            <Box
                                                component="img"
                                                src={reply.answer}
                                                alt="uploaded file"
                                                sx={{
                                                    width: "100%",
                                                    maxWidth: 200
                                                }}
                                            />
                                        ) : (
                                            reply.answer
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        ))}

                        {guestDetails?.uuid && (
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRows.includes("guestUuid")}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, "guestUuid")
                                        }
                                    />
                                </TableCell>
                                <TableCell>QR Code</TableCell>
                                <TableCell>
                                    <QrGenerator
                                        guestUuid={guestDetails?.uuid}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {isPrint && (
                <Box sx={{ mt: 2, mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Print Settings</Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Page Width"
                                type="number"
                                color="warning"
                                value={pageWidth}
                                onChange={handlePageWidthChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                }}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label moves above the input when a value is present
                                }}
                                inputProps={{
                                    step: 0.1,
                                    min: 0.1,
                                }}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Page Height"
                                type="number"
                                color="warning"
                                value={pageHeight}
                                onChange={handlePageHeightChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                }}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label moves above the input when a value is present
                                }}
                                inputProps={{
                                    step: 0.1,
                                    min: 0.1,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOpenModal(true);
                                    setShowLayoutEditor(true);
                                    setPreviewScale(1);
                                }}
                                sx={{
                                    backgroundColor: "#FF8359",
                                    ":hover": {
                                        backgroundColor: "#FF8359"
                                    },
                                    width: '100%'
                                }}
                            >
                                Customize Layout
                            </Button>
                        </Grid>
                    </Grid>

                    {!openModal && (
                        <Grid container sx={{ mt: 2 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handlePrint}
                                sx={{
                                    backgroundColor: "#FF8359",
                                    ":hover": {
                                        backgroundColor: "#FF8359"
                                    }
                                }}
                            >
                                Print Selected Data
                            </Button>
                        </Grid>
                    )}
                </Box>
            )}

            {/* Full screen modal for layout editor */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                keepMounted={true}
                aria-labelledby="layout-editor-modal"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        width: '90%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 1,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h2">Layout Editor</Typography>
                        <Box display='flex' justifyContent='space-evenly'>
                            <IconButton
                                onClick={() => setPreviewScale(prev => Math.min(prev + 0.1, 2))}
                                sx={{ maxWidth: '30%' }}
                            >
                                <ZoomInIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => setPreviewScale(prev => Math.max(prev - 0.1, 0.5))}
                                sx={{ maxWidth: '30%' }}
                            >
                                <ZoomOutIcon />
                            </IconButton>
                            <IconButton onClick={() => setOpenModal(false)} sx={{ maxWidth: '30%' }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {collisionDetected && (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            Content overlap detected. Please adjust positions to avoid overlapping elements.
                        </Alert>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: '1rem', md: 0 }, height: { xs: 'auto', md: 'calc(100% - 125px)' }, overflow: 'auto' }}>
                        {/* Left panel - Preview */}
                        <Box ref={containerRef} sx={{
                            flex: { xs: '1 1 100%', md: '1 1 70%' },
                            overflow: 'auto',
                            p: 2,
                            bgcolor: '#f5f5f5',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            minHeight: { xs: '300px', md: 'auto' }
                        }}>
                            <PreviewContent />
                        </Box>

                        {/* Right panel - Controls */}
                        <Box sx={{
                            flex: { xs: '1 1 100%', md: '1 1 30%' },
                            minHeight: { xs: '250px', md: '35vh' },
                            p: 2,
                            overflow: 'auto'
                        }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Page Settings
                            </Typography>

                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Width"
                                        type="number"
                                        color="warning"
                                        value={pageWidth}
                                        onChange={handlePageWidthChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label moves above the input when a value is present
                                        }}
                                        inputProps={{
                                            step: 0.1,
                                            min: 0.1,
                                        }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Height"
                                        type="number"
                                        color="warning"
                                        value={pageHeight}
                                        onChange={handlePageHeightChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                        }}
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label moves above the input when a value is present
                                        }}
                                        inputProps={{
                                            step: 0.1,
                                            min: 0.1,
                                        }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            {selectedRows.includes("guestUuid") && (
                                <>
                                    <Typography variant="subtitle1" gutterBottom>
                                        QR Code Settings
                                    </Typography>
                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="QR Code Size"
                                                type="number"
                                                color="warning"
                                                value={qrCodeSize}
                                                onChange={handleQRSizeChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                                }}
                                                inputProps={{
                                                    step: 5,
                                                    min: 30,
                                                }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" gutterBottom>
                                Instructions
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                • Drag any element to position it precisely
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                • Adjust QR code size using the input field
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                • Use zoom controls to better position elements
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                • All content is automatically constrained to fit the page
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 3 }}>
                                • Avoid element overlap (marked with warning)
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', pt: 2, borderTop: '1px solid #e0e0e0' }}>
                        <Button
                            variant="contained"
                            onClick={saveLayout}
                            sx={{
                                backgroundColor: "#FF8359",
                                ":hover": {
                                    backgroundColor: "#FF8359"
                                }
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenModal(false);
                                handlePrint();
                            }}
                            sx={{
                                backgroundColor: "#FF8359",
                                ":hover": {
                                    backgroundColor: "#FF8359"
                                }
                            }}
                        >
                            Apply & Print
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <div style={{ display: "none" }}>
                <PrintableContent
                    ref={printRef}
                    selectedRows={selectedRows}
                    guestDetails={guestDetails}
                    additionalDetails={additionalDetails}
                    eventData={eventData}
                    amountCurrencySymbol={amountCurrencySymbol}
                    parseDecimalNumber={parseDecimalNumber}
                    pageWidth={pageWidth}
                    pageHeight={pageHeight}
                    qrCodeSize={qrCodeSize}
                    qrCodePosition={qrCodePosition}
                    qrCodeAlignment="center"
                    qrCodeDisplay="block"
                    elementsPositions={elementsPositions}
                />
            </div>
        </Box>
    );
}
export default GuestDetailsTable;