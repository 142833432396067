import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import noImage from '../../../../../../../../assets/no-image.webp';
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { userStatus } from "../../../../../../../../redux/slices/userDataSlice";
import { BACKEND_URL } from "../../../../../../../../utils/constants/apis.urls";
import { ALL_PERMISSION_ROLES, READ_PERMISSION_ROLES } from "../../../../../../../../utils/constants/roles";
import AddPhotos from './components/AddPhotos';
import DeleteAlbumDialog from "./components/DeleteAlbumDialog";
import EditAlbumDialog from "./components/EditAlbumDialog";
import AlbumGalleryDialog from './components/AlbumGalleryDialog';
import { isPreview } from '../../../../../../../../redux/slices/eventDataSlice';

const GroupAlbumCard = ({ setItems, albumDetails, handleAlbumMedia }) => {
    const isLoggedIn = useSelector(userStatus);
    const isVisible = useSelector(isPreview);
    const groupData = useSelector(groupDetails);
    const defaultPhoto = noImage
    const [previewFile, setPreviewFile] = useState(albumDetails?.media?.length > 0 ? `${albumDetails?.media?.[0].path}` : null);
    const [uploading, setUploading] = useState(false);

    let [albumDialogStates, setAlbumDialogStates] = useState({
        edit: false,
        delete: false,
        gallery: false
    });
    const handleOpen = (operation) => {
        setAlbumDialogStates({ ...albumDialogStates, [operation]: true });
    }
    const handleClose = (operation) => {
        setAlbumDialogStates({ ...albumDialogStates, [operation]: false });
    }
    const handleUploadChanges = (val) => {
        setUploading(val);
    }

    return (
        <>
            <Box className='album-card-design' sx={{
                borderRadius: '0.5rem',
                width: '18rem',
                height: '12rem',
                display: "flex",
                userSelect: "none",
                position: 'relative',
                m: '8px 1.5rem 8px 8px',
                backgroundColor: '#000000'
                // boxShadow: previewFile ? 'inset 0px -15px 75px 30px rgba(0,0,0,0.9)' : 'none'
            }}>
                {!uploading ?
                    <Box
                        component='img'
                        src={previewFile ? (
                            typeof (previewFile) === 'object' ?
                                URL.createObjectURL(previewFile) :
                                previewFile)
                            : defaultPhoto
                        }
                        alt='Img.'
                        onClick={() => albumDetails.type === 'photos' ? handleOpen('gallery') : window.open(albumDetails.album_link, '_blank')}
                        sx={{
                            opacity: previewFile ? 0.5 : 0.9,
                            width: '100%',
                            borderRadius: '0.5rem',
                            // position: 'relative',
                            // zIndex: -2,
                            objectFit: 'cover',
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}
                    />
                    :
                    <Stack justifyContent='center' alignItems='center'
                        sx={{
                            width: '100%',
                            px: '1rem',
                            my: '2rem',
                        }}>
                        <CircularProgress color="warning" />
                    </Stack>
                }
                {/* <Box
                    className="shadow"
                    onClick={() => console.log("Open images")}
                    sx={{
                        ":hover": {
                            cursor: "pointer !important"
                        }
                    }}
                ></Box> */}

                <Box sx={{ opacity: 1 }}>

                    {ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) && isVisible ?
                        <Stack direction='row' spacing={2}
                            sx={{
                                p: '8px 3px 0px 0px',
                                position: 'absolute',
                                top: '5px',
                                right: '0.5rem',
                            }}>
                            <IconButton sx={{ p: 0 }} onClick={() => handleOpen('edit')}>
                                <EditIcon sx={{
                                    color: previewFile ? 'white' : 'black',
                                    width: '1.3rem', height: '1.3rem'
                                }} />
                            </IconButton>
                            <IconButton sx={{ p: 0 }} onClick={() => handleOpen('delete')}>
                                <DeleteIcon sx={{
                                    color: previewFile ? 'white' : 'black',
                                    width: '1.3rem', height: '1.3rem'
                                }} />
                            </IconButton>
                        </Stack>
                        : null
                    }

                    <Stack alignItems='center' direction='row'
                        sx={{
                            width: '100%',
                            position: 'absolute',
                            bottom: '5px',
                            left: '0.5rem',
                        }}>
                        <Box onClick={() => handleOpen('gallery')} sx={{
                            flex: 1,
                            ":hover": {
                                cursor: 'pointer'
                            }
                        }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                                color: previewFile ? 'white' : 'black',
                            }}>
                                {albumDetails?.title}
                            </Typography>
                            {albumDetails.type === 'photos' ?
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    color: previewFile ? 'white' : 'black',
                                }}>
                                    {albumDetails?.photos} Media
                                </Typography>
                                : null
                            }
                        </Box>
                        {
                            albumDetails.type === 'photos' && (ALL_PERMISSION_ROLES.includes(groupData?.current_user_role) ||
                                (isLoggedIn &&
                                    (READ_PERMISSION_ROLES.includes(groupData?.current_user_role) && albumDetails?.allow_guest_upload)
                                )) ?
                                <AddPhotos
                                    handleAlbumMedia={handleAlbumMedia}
                                    albumDetails={albumDetails}
                                    setPreviewFile={setPreviewFile}
                                    handleUploadChanges={handleUploadChanges}
                                />
                                : null
                        }
                    </Stack>

                </Box>

            </Box>

            {albumDialogStates.edit ?
                <EditAlbumDialog setItems={setItems} albumDetails={albumDetails}
                    handleClose={() => handleClose('edit')} /> : null
            }
            {albumDialogStates.delete ?
                <DeleteAlbumDialog setItems={setItems} albumDetails={albumDetails}
                    handleClose={() => handleClose('delete')} /> : null
            }
            {albumDialogStates.gallery &&
                (
                    <AlbumGalleryDialog
                        handleAlbumMedia={handleAlbumMedia}
                        albumDetails={albumDetails}
                        handleClose={(images) => {
                            handleClose('gallery')
                            setPreviewFile(images?.length > 0 ?
                                `${images?.[images.length - 1].path}` : null)
                        }
                        }
                    />
                )
            }
        </>
    )
};

export default GroupAlbumCard;
