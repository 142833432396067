import { useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from "react";
import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import GuestList from "./components/GuestList";
import GuestFooter from "./components/GuestFooter";
import GuestHeader from "./components/GuestHeader";
import { userStatus } from "../../../../../../redux/slices/userDataSlice";
import { EventGuestsContext } from "./EventGuestContext";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";
import { getEventGuests } from "../../../../../../services/wEvents/eventGuests";
import { getGuestDetails } from "../../../../../../services/wEvents/eventGuests";
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import EventGuestTabs from "./EventGuestTabs";
import LoginDialog from "../../../../../../components/LoginDialog";

const EventGuests = () => {
    const eventData = useSelector(eventDetails);
    let totalGuests = useRef(null);
    let per_page_guests = useRef(5);

    const [guests, setGuests] = useState(null);
    const [pageDimension, setPageDimension] = useState({
        height: eventData?.event_print_height ?? 0,
        width: eventData?.event_print_width ?? 0
    });
    const [elementsPositions, setElementsPositions] = useState({});
    const isLoggedIn = useSelector(userStatus);
    const [searchedGuest, setSearchedGuest] = useState(null);
    const [checkedGuests, setChecked] = useState([]);
    const [isCheckboxVisible, setVisible] = useState(false);
    const [inviteMsg, setInviteMsg] = useState(null);
    const [paymentDone, setPayment] = useState(false); //Set to false when integrating payment module
    const [paymentId, setPaymentId] = useState(null); //Set ID when payment done
    const [scheduledDate, setScheduledDate] = useState(null);
    const [guestInviteActiveStep, setGuestInviteActiveStep] = useState(0);
    const [updatedCategoryCounts, setupdatedCounts] = useState(null)
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    const [tabValue, setTabValue] = useState((isAllPermission || eventData?.current_user_role === 'manager') ? 0 : null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [loginDialog, setLoginDialog] = useState(false);
    const [guestData, setGuestData] = useState(null);

    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checkedGuests.indexOf(value);
        const newChecked = [...checkedGuests];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const isSmallDevice = useMediaQuery("(max-width:600px)"); // Smaller than tablets

    useEffect(() => {
        // fetch list of event guests only if event owner/manager is logged in
        if (isLoggedIn) {
            setGuests(null);
            if (!tabValue) {
                setChecked([]);
            }
            getEventGuests({ eventId: eventData?.id, page: 1, type: tabValue === 0 ? 'invited' : 'registered', searchedGuest: searchedGuest }).then((res) => {
                totalGuests.current = res.data.meta.total;
                per_page_guests.current = res.data.meta.per_page;
                setGuests(res.data.data);
                setElementsPositions(res?.data?.data[0]?.print_layout ?? {})
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [tabValue, loginDialog]);

    useEffect(() => {
        // open login dialog if event owner/manager try to access guests section without logging in
        if (!isLoggedIn) {
            getGuestDetails({ eventId: eventData?.id, uuid: eventData?.guest_uuid }).then((result) => {
                setLoginDialog(true);
                setGuestData(result.data.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        setPageDimension({
            height: eventData?.event_print_height ?? 0,
            width: eventData?.event_print_width ?? 0
        })
    }, [eventData?.event_print_height, eventData?.event_print_width])

    return (
        <>
            <EventGuestsContext.Provider value={{
                tabValue, guests, setGuests, totalGuests, per_page_guests, elementsPositions, setElementsPositions,
                pageDimension, setPageDimension,
                searchedGuest, setSearchedGuest, inviteMsg, setInviteMsg, isCheckboxVisible, setVisible,
                checkedGuests, setChecked, guestInviteActiveStep, setGuestInviteActiveStep,
                paymentDone, setPayment, paymentId, setPaymentId, scheduledDate, setScheduledDate, handleToggle, updatedCategoryCounts, setupdatedCounts
            }}>
                {
                    isLoggedIn ?
                        <Stack sx={{ position: 'relative' }}>
                            <GuestHeader tabValue={tabValue} handleTabChange={handleTabChange} />
                            <Divider sx={{ my: 1 }} />
                            {
                                isSmallDevice ?
                                    <EventGuestTabs tabValue={tabValue} handleTabChange={handleTabChange} />
                                    : <GuestList />
                            }
                            {/* As we are showing options of cancel and invite in footer only in small devices */}
                            {
                                (totalGuests.current > 0 && isAllPermission) ?
                                    (isSmallDevice ? <GuestFooter /> : null)
                                    : null
                            }
                            <Box id='container'></Box>
                        </Stack> : null
                }
                {loginDialog ? <LoginDialog open={true} handleClose={handleDialogClose} guestData={guestData} /> : null}
            </EventGuestsContext.Provider>
        </>
    );
};

export default EventGuests;