export const tabsMapping = {
    'dashboard': 'Dashboard',
    'pricing': 'Pricing',
    'payments': 'Payments',
    'profile': 'Profile',
    'create-event': 'Create Event',
    'event-details': 'Event Details',
    'event-overview': 'Event Details',
    'event-reports': 'Event Reports',
    'microsite-builder': 'Microsite Builder',
    'e': 'Event Details',
    'event': 'Event Details',
    'create-group': 'Create Organization',
    'group-details': 'Organization Details',
    'g': 'Organization Details',
    'bank-details': 'Bank Details',
}